import { AccountType, SessionState, UserSession, UserType } from '@arland-bmnext/api-data'
import { IncomingMessage } from 'http'
import { NextApiRequest, NextApiResponse, NextPageContext } from 'next'
import { applySession, Session as IronSession, SessionOptions, withIronSession } from 'next-iron-session'
import { useCommonClient, useSessionClient } from './api-client'
import { FrontEndSettingsDTO, TokenDTO } from '@arland-bmnext/webapps-api-data'
import { AxiosClient } from './axiosclient'
import { PageFilterResult, PageFilterType } from '../filter/types'
import { handlePageFilter } from '../filter/handler'
import { floorNumber } from '../util/number'
import { CmsPageIdentifier, getCmsPage, getFrontendResources, getFrontendSettings } from './content'
import { SessionData, SessionId } from './api-client/core'
import dayjs from 'dayjs'
import { FrontEndResources } from '@bmnext-cms/types/content'
import { evalDisplayPredicates } from '../util/display-predicate'
import { getUser, getUserAccounts } from './user'

export type Session = IronSession & {
  token: TokenDTO
  id: SessionId
  authenticated: boolean
  userId: number
  languageId: number
  loggedOnAt: number
  lastSessionTimeRenewal: number
  maximumMinutesToPlayPerSession: number
}

const applySessionValues = async (req: IncomingMessage, session: Session, locale: string) => {
  const sessionClient = useSessionClient()
  const commonClient = useCommonClient()

  // Create API Token
  let token: TokenDTO = session.get('token')
  if (token == null || token.exp < dayjs().unix()) {
    try {
      token = await sessionClient.createAuthToken(buildSessionData(req, undefined, undefined), {
        clientId: process.env.CLIENT_ID,
        secret: process.env.CLIENT_SECRET,
      })
      session.set('token', token)
    } catch (error) {
      console.log('Could not create auth token', error)
    }
  }

  // TODO: improve: is getting the session needed? alternatives?
  let _session = null
  const sessionId: string = session.get('sessionId')
  if (sessionId != null && sessionId !== '') {
    try {
      _session = await sessionClient.get(buildSessionData(req, sessionId, token))
    } catch (error) {
      console.log('Get existing session failed!')
    }
  }

  // Check if session is present/active/valid, otherwise create new one
  if (_session == null || _session.state != SessionState.Active || !isSessionLifeTimeValid(_session)) {
    try {
      _session = await sessionClient.create(buildSessionData(req, undefined, token), getIpAddress(req) || '::1')
      session.set('loggedOnAt', undefined)
      session.set('lastSessionTimeRenewal', undefined)
    } catch (error) {
      console.log('Could not create session!', error)
    }
  }

  if (_session) {
    session.set('sessionId', _session.sessionToken)
    session.set('userId', _session.user?.id)
    session.set('authenticated', _session.user?.type === UserType.Customer || false)

    // TODO: improve: how to efficiently get the languageId for the current locale? getLanguageByShortSign call?
    const languageId = session.get('languageId')
    if (languageId == null) {
      try {
        const languages = await commonClient.languages.getAll(buildSessionData(req, _session.sessionToken, token))
        if (languages) {
          const language = languages.find((x) => x.shortSign === locale)
          session.set('languageId', language.id)
        }
      } catch (error) {
        console.log('Could not load languages!')
        session.set('languageId', 1)
      }
    }

    await session.save()
  }
}

const isSessionLifeTimeValid = (session: UserSession) => {
  const now = dayjs(new Date())
  const expireDate = dayjs(session.started).add(session.sessionLifeTimeMinutes, 'minutes')
  return !now.isAfter(expireDate)
}

const getSessionOptions = (): SessionOptions => {
  const password = process.env.COOKIE_PASSWORD

  if (!password) throw new Error('env var COOKIE_PASSWORD not set')

  return {
    password,
    cookieName: process.env.COOKIE_NAME,
    cookieOptions: {
      httpOnly: true,
      sameSite: 'lax',
      // the next line allows to use the session in non-https environments like
      // Next.js dev mode (http://localhost:3000)
      // secure: process.env.NODE_ENV === 'production',
      secure: false,
    },
  }
}

type NextIronPageContext = NextPageContext & {
  session: Session
}

type NextIronPageHandler<Result> = (
  ctx: NextIronPageContext,
  pageFilterResult: PageFilterResult,
  pageData: {
    cmsPage: any
    frontendSettings: FrontEndSettingsDTO
    frontendResources: FrontEndResources
  },
) => Result | Promise<Result>

const sessionHandler = async (
  ctx: NextIronPageContext,
  options: SessionOptions,
  handler: NextIronPageHandler<any>,
  exemptedFilterTypes: PageFilterType[],
  pageIdentifier: CmsPageIdentifier | string | null,
) => {
  try {
    const startTime = performance.now()

    await applySession(ctx.req, ctx.res, options)
    ctx.session = (ctx.req as any).session
    if (!ctx.req) throw new Error('Context request not set')
    await applySessionValues(ctx.req, ctx.session, ctx.locale)

    const authenticated = ctx.session.get('authenticated')
    const cmsPageIdentifier = pageIdentifier ?? (ctx.query?.slug as string) ?? null
    const cmsPage = cmsPageIdentifier ? await getCmsPage(ctx.req, ctx.session, cmsPageIdentifier) : null
    const frontendSettings = await getFrontendSettings(ctx.req, ctx.session)
    const frontendResources = await getFrontendResources(ctx.req, ctx.session)
    const user = await getUser(ctx.req, ctx.session)
    const userAccounts = authenticated ? await getUserAccounts(ctx.req, ctx.session, user.id) : []
    const mainAccount = userAccounts?.find((account) => account.type === AccountType.Main)

    const pageFilterResult = await handlePageFilter(ctx.req, ctx.locale, exemptedFilterTypes, cmsPage, frontendSettings)
    if (pageFilterResult?.redirect) {
      return { redirect: pageFilterResult.redirect }
    } else if (pageFilterResult?.notFound) {
      return { notFound: true }
    }

    const displayFilters = cmsPage?.displayFilter ? JSON.parse(cmsPage.displayFilter) : null
    if (displayFilters != null && displayFilters.length > 0) {
      const isPageAccessible = evalDisplayPredicates(displayFilters, user, mainAccount)
      if (!isPageAccessible) {
        return { notFound: true }
      }
    }

    const result = await handler(ctx, pageFilterResult, { cmsPage, frontendSettings, frontendResources })

    const endTime = performance.now()
    console.log(
      `${dayjs().format('YYYY-MM-DD | hh:mm:ss.SSS')} | PAGE | ${floorNumber(endTime - startTime, 2)}ms | [${
        ctx.req?.url
      }]`,
    )

    return result
  } catch (error) {
    console.log(`${dayjs().format('YYYY-MM-DD | hh:mm:ss.SSS')} | [ERROR] | sessionHandler`, error)
  }
}

type NextIronApiRequest = NextApiRequest & { session: Session }

type NextIronApiHandler<Result> = (req: NextIronApiRequest, res: NextApiResponse) => Result | Promise<Result>

export const withApiSession = <Result>(handler: NextIronApiHandler<Result>): NextIronApiHandler<Result> => {
  return withIronSession(async (req: NextIronApiRequest, res: NextApiResponse) => {
    try {
      const startTime = performance.now()

      // Validate API Token
      let token: TokenDTO = req.session.get('token')
      if (token == null || token.exp < dayjs().unix()) {
        try {
          const sessionClient = useSessionClient()
          token = await sessionClient.createAuthToken(buildSessionData(req, undefined, undefined), {
            clientId: process.env.CLIENT_ID,
            secret: process.env.CLIENT_SECRET,
          })
          req.session.set('token', token)
          await req.session.save()
        } catch (error) {
          console.log('Could not create auth token', error)
        }
      }

      const handlerResult = await handler(req, res)

      const endTime = performance.now()
      console.log(
        `${dayjs().format('YYYY-MM-DD | hh:mm:ss.SSS')} | API | ${floorNumber(endTime - startTime, 2)}ms | [${req?.url}]`,
      )

      return handlerResult
    } catch (error) {
      console.log(`${dayjs().format('YYYY-MM-DD | hh:mm:ss.SSS')} | [ERROR] | withApiSession`, error)
    }
  }, getSessionOptions())
}

export const withPageSession = <Result>(
  handler: NextIronPageHandler<Result>,
  pageIdentifier: CmsPageIdentifier,
  exemptedFilterTypes: PageFilterType[] = [],
): NextIronPageHandler<Result> => {
  return (ctx: NextIronPageContext) => {
    return sessionHandler(
      ctx,
      getSessionOptions(),
      (ctx, pageFilterResult, { cmsPage, frontendSettings, frontendResources }) =>
        handler(ctx, pageFilterResult, { cmsPage, frontendSettings, frontendResources }),
      exemptedFilterTypes,
      pageIdentifier,
    )
  }
}

export const buildSessionData = (
  req: IncomingMessage | NextIronApiRequest,
  sessionId: SessionId,
  token: TokenDTO,
  exatoValidationId?: string,
) => {
  const data: SessionData = {}
  const ipAddress = getIpAddress(req)

  if (token != null) data.token = token
  if (sessionId != null) data.sessionId = sessionId
  if (req.headers.origin != null || req.headers.host != null)
    data.origin = req.headers.origin ?? 'http://' + req.headers.host
  if (req.headers['user-agent'] != null) data.useragent = req.headers['user-agent']
  if (ipAddress != null) data.ipAddress = ipAddress
  if (exatoValidationId) data.exatoValidationId = exatoValidationId
  return data
}

const getIpAddress = (req: IncomingMessage) => {
  let ip = null

  if (req.headers['x-forwarded-for']) {
    ip = (req.headers['x-forwarded-for'] as string).split(',')[0]
  } else if (req.headers['x-real-ip']) {
    ip = req.socket.remoteAddress
  } else {
    ip = req.socket.remoteAddress
  }

  return ip
}

export const getSessionInformation = async (): Promise<{ languageId: number; sessionId: SessionId }> => {
  return await AxiosClient.get<{ languageId: number; sessionId: SessionId }>('/api/session-info')
}

export const ensureSession = async (req: NextIronApiRequest): Promise<{ sessionId: string; token: TokenDTO }> => {
  const sessionClient = useSessionClient()
  let sessionId = req.session.get('sessionId')
  let token = req.session.get('token')

  if (token == null) {
    token = await sessionClient.createAuthToken(buildSessionData(req, undefined, undefined), {
      clientId: process.env.CLIENT_ID,
      secret: process.env.CLIENT_SECRET,
    })
  }

  if (sessionId == null) {
    sessionId = (await sessionClient.create(buildSessionData(req, undefined, token), getIpAddress(req) || '::1'))
      ?.sessionToken
  }

  return { sessionId, token }
}
