import { getCookie, setCookie } from 'cookies-next'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

export const usePageCookies = (): [
  boolean,
  boolean,
  (_essentialCookiesAccepted: boolean, _performanceCookiesAccepted: boolean) => void,
] => {
  const [essentialCookiesAccepted, setEssentialCookiesAccepted] = useState(null)
  const [performanceCookiesAccepted, setPerformanceCookiesAccepted] = useState(null)

  useEffect(() => {
    let _essentialCookiesAccepted = null
    let _performanceCookiesAccepted = null

    const consentCookie = getCookie('consent')
    if (consentCookie) {
      const consentData = JSON.parse(consentCookie.toString())
      _essentialCookiesAccepted = Boolean(consentData.essential) ?? null
      _performanceCookiesAccepted = Boolean(consentData.performance) ?? null
    }

    setEssentialCookiesAccepted(_essentialCookiesAccepted)
    setPerformanceCookiesAccepted(_performanceCookiesAccepted)
  }, [])

  const setCookieValues = (_essentialCookiesAccepted: boolean, _performanceCookiesAccepted: boolean): void => {
    setEssentialCookiesAccepted(_essentialCookiesAccepted)
    setPerformanceCookiesAccepted(_performanceCookiesAccepted)

    const consent = {
      essential: _essentialCookiesAccepted,
      performance: _performanceCookiesAccepted,
    }

    setCookie('consent', consent, { expires: dayjs().add(10, 'years').toDate() })
  }

  return [essentialCookiesAccepted, performanceCookiesAccepted, setCookieValues]
}
