import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { FieldErrors, UseFormRegister } from 'react-hook-form'
import { SelectOption } from '../../../util/select-option'
import { HelpTextProps } from '../../HelpText'
import { FormElementErrorMessage, FormElementLabel } from './Input'

export type SelectProps = {
  label?: string
  required?: boolean
  showOptionalIfNotRequired: boolean
  property?: string
  selectOptions?: SelectOption[]
  validators?: any
  errors?: FieldErrors
  register?: UseFormRegister<any>
  value?: any
  onChange?: (...args: any) => void
  additionalActionText?: string
  additionalActionFn?: (...args) => any
  helpText?: HelpTextProps
}

export const Select = ({
  label = '',
  required = false,
  showOptionalIfNotRequired = true,
  property = undefined,
  selectOptions = [],
  validators = {},
  errors = undefined,
  register = (...args) => null,
  setValue = (...args) => null,
  value = undefined,
  onChange = (...args) => null,
  additionalActionText = '',
  additionalActionFn = null,
  helpText = null,
  backgroundColor = null,
  textColor = null,
  readonly = false,
  disabled = false,
}) => {
  const [currentValue, setCurrentValue] = useState(value)

  const _onChange = (ev) => {
    const _value = ev?.target?.value

    setCurrentValue(_value)
    onChange(ev)

    setValue(property, _value, {
      shouldValidate: true,
      shouldTouch: true,
    })
  }

  return (
    <div className="form-select flex flex-col mb-4">
      <FormElementLabel
        label={label}
        property={property}
        required={required}
        showOptionalIfNotRequired={showOptionalIfNotRequired}
        helpText={helpText}
      />

      <div
        className={`form-input-inner flex space-x-2 items-center transition relative
         ${backgroundColor ? backgroundColor : 'bg-form hover:bg-formHover'} ${
           textColor ? textColor : 'text-formContrast hover:text-formHoverContrast'
         } border-[.5px] border-formBorder hover:border-formHoverBorder rounded-md h-[42px] px-4 py-[10px] ${
           !disabled && !readonly ? 'focus:border-primary' : ''
         } ${errors != null ? 'border-red-500 border-opacity-100' : ''} ${readonly || disabled ? ' opacity-60' : ''}`}
      >
        <select
          name={property}
          {...register(property, { required: required, validate: validators })}
          className={`form-select-select flex w-full h-full break-all text-sm bg-transparent focus:outline-none appearance-none cursor-pointer`}
          onChange={_onChange}
          value={currentValue}
        >
          {selectOptions.map((option, index) => (
            <option key={index} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>

        <FontAwesomeIcon
          icon={faChevronDown}
          className="absolute right-3 bottom-[13px] opacity-60 pointer-events-none"
        />
      </div>

      {additionalActionText && (
        <div
          className="form-select-additional-action flex justify-end w-full text-primary text-xs font-semibold mt-2 cursor-pointer"
          onClick={additionalActionFn}
        >
          {additionalActionText}
        </div>
      )}

      <FormElementErrorMessage errors={errors} />
    </div>
  )
}
