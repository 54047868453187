import {
  ChangePasswordRequest,
  ChangePinRequest,
  LockDuration,
  RegistrationRequest,
  SignInRequest,
  TwoFARequestModel,
  TwoFASettings,
} from './model'
import { deleteJson, getJson, HttpClient, PagedResult, postFormDataJson, postJson, putJson, SessionData } from '../core'
import {
  Account,
  Bet,
  BetSlipState,
  BookBonusResult,
  BranchBetSettings,
  BranchPasswordPolicySettings,
  BranchSecuritySettings,
  ChangeSecurityQuestionRequest,
  ChangeUserRequest,
  CheckCpfResult,
  Country,
  Currency,
  DocumentTypes,
  ExatoUrlResult,
  FaceRecognitionTriggerReason,
  GeoComplyGeolocationRequest,
  GeoComplyLicense,
  GeoComplyLicenseRequest,
  GivenBonusListItem,
  GivenBonusState,
  Language,
  PasswordError,
  Rating,
  RedeemVoucherResult,
  RegisterRequirements,
  ResetPasswordResult,
  ResetPasswordStatusCode,
  SecurityQuestion,
  User,
  UserAccountingListItem,
  UserDefinedBetSettings,
  UserDefinedMarketingSettings,
  UserDisplayFormatPreference,
  UserDocument,
  UserIdentificationType,
  UserLogonResult,
  UserPrivacyPolicy,
  UserPrivacyPolicyRequest,
  UserResult,
  UserSelfLockErrorCode,
  UserTermsAndConditions,
  UserTermsAndConditionsRequest,
  ValidateTFAResult,
} from '@arland-bmnext/api-data'
import {
  AffiliateDTO,
  CommunicationSettingsDTO,
  PrivacyPolicyCheckResultDTO,
  RequiredFieldResultDTO,
  TermsAndConditionsCheckResultDTO,
  UserLogonWithValidateTokenDTO,
} from '@arland-bmnext/webapps-api-data'
import { CpfCheckRequest } from '@arland-bmnext/api-data/auth/cpfCheckRequest'

export class AccountClient {
  constructor(private readonly httpClient: HttpClient) {}

  // Authentication

  signInUser(sessionData: SessionData, request: SignInRequest): Promise<UserLogonResult> {
    return postJson<SignInRequest, UserLogonResult>(this.httpClient, ['api', 'auth', 'login'], request, sessionData)
  }

  signOutUser(sessionData: SessionData): Promise<boolean> {
    return postJson<null, boolean>(this.httpClient, ['api', 'auth', 'logout'], null, sessionData)
  }

  signInUser2FA(sessionData: SessionData, request: TwoFARequestModel): Promise<ValidateTFAResult> {
    return postJson<TwoFARequestModel, ValidateTFAResult>(this.httpClient, ['api', 'auth', '2fa'], request, sessionData)
  }

  getBranchSecuritySettings(sessionData: SessionData): Promise<BranchSecuritySettings> {
    return getJson<BranchSecuritySettings>(this.httpClient, ['api', 'auth', 'securitysettings'], sessionData)
  }

  resendVerificationEmail(sessionData: SessionData, userId: number): Promise<string> {
    return postJson<null, string>(this.httpClient, ['api', 'auth', 'resendemail', userId], null, sessionData)
  }

  // Registration

  registerUser(sessionData: SessionData, request: RegistrationRequest): Promise<UserResult> {
    return postJson<RegistrationRequest, UserResult>(
      this.httpClient,
      ['api', 'auth', 'registration'],
      request,
      sessionData,
    )
  }

  completeRegistration(sessionData: SessionData, activationToken: string): Promise<UserLogonWithValidateTokenDTO> {
    return postJson<any, UserLogonWithValidateTokenDTO>(
      this.httpClient,
      ['api', 'auth', 'completeregistration'],
      { activationToken },
      sessionData,
    )
  }

  getSecurityQuestions(sessionData: SessionData): Promise<PagedResult<SecurityQuestion>> {
    return getJson<PagedResult<SecurityQuestion>>(this.httpClient, ['api', 'auth', 'securityquestions'], sessionData)
  }

  getRequiredFields(sessionData: SessionData): Promise<RegisterRequirements> {
    return getJson<RegisterRequirements>(
      this.httpClient,
      ['api', 'auth', 'registration', 'requiredfields'],
      sessionData,
    )
  }

  getPasswordPolicy(sessionData: SessionData): Promise<BranchPasswordPolicySettings> {
    return getJson<BranchPasswordPolicySettings>(this.httpClient, ['api', 'auth', 'passwordpolicy'], sessionData)
  }

  netrinIsCpfValidCheck(sessionData: SessionData, cpf: string): Promise<boolean> {
    return postJson<CpfCheckRequest, boolean>(
      this.httpClient,
      ['api', 'auth', 'cpf', 'netrin', 'check'],
      { cpf },
      sessionData,
    )
  }

  netrinCpfCheckResult(sessionData: SessionData, cpf: string): Promise<CheckCpfResult> {
    return postJson<CpfCheckRequest, CheckCpfResult>(
      this.httpClient,
      ['api', 'auth', 'netrin', 'cpf', 'check'],
      { cpf },
      sessionData,
    )
  }

  paybrokersIsCpfValidCheck(sessionData: SessionData, cpf: string): Promise<boolean> {
    return postJson<CpfCheckRequest, boolean>(
      this.httpClient,
      ['api', 'auth', 'cpf', 'paybrokers', 'check'],
      { cpf },
      sessionData,
    )
  }

  paybrokersCpfCheckResult(sessionData: SessionData, cpf: string): Promise<CheckCpfResult> {
    return postJson<CpfCheckRequest, CheckCpfResult>(
      this.httpClient,
      ['api', 'auth', 'paybrokers', 'cpf', 'check'],
      { cpf },
      sessionData,
    )
  }

  // User

  getUserByIdentificationType(
    sessionData: SessionData,
    identity: number | string,
    identificationType: UserIdentificationType,
  ): Promise<User> {
    return getJson<User>(this.httpClient, ['api', 'users', identity, identificationType], sessionData)
  }

  isUserIdentifierUnique(sessionData: SessionData, type: UserIdentificationType, identifier: string): Promise<boolean> {
    return getJson<boolean>(this.httpClient, ['api', 'users', 'isuniqueidentification', identifier, type], sessionData)
  }

  getUserProfile(sessionData: SessionData): Promise<User> {
    return getJson<User>(this.httpClient, ['api', 'account', 'profile'], sessionData)
  }

  changeUserProfile(sessionData: SessionData, request: ChangeUserRequest): Promise<UserResult> {
    return postJson<ChangeUserRequest, UserResult>(this.httpClient, ['api', 'account', 'profile'], request, sessionData)
  }

  getUserAccounts(sessionData: SessionData, userId: number): Promise<Account[]> {
    return getJson<Account[]>(this.httpClient, ['api', 'account', userId], sessionData)
  }

  lockAccountTemporary(sessionData: SessionData, lockDuration: LockDuration): Promise<UserSelfLockErrorCode> {
    const query = {
      duration: lockDuration.toString(),
    }

    return postJson<null, UserSelfLockErrorCode>(
      this.httpClient,
      ['api', 'account', 'lockuntil'],
      null,
      sessionData,
      query,
    )
  }

  closeAccount(sessionData: SessionData): Promise<UserSelfLockErrorCode> {
    return postJson<null, UserSelfLockErrorCode>(this.httpClient, ['api', 'account', 'lock'], null, sessionData)
  }

  getKycRequiredFields(sessionData: SessionData): Promise<RequiredFieldResultDTO> {
    return getJson<RequiredFieldResultDTO>(this.httpClient, ['api', 'settings', 'kycrequiredfields'], sessionData)
  }

  getCommunicationSettings(sessionData: SessionData): Promise<CommunicationSettingsDTO> {
    return getJson<CommunicationSettingsDTO>(this.httpClient, ['api', 'communication'], sessionData)
  }

  changeCommunicationSettings(
    sessionData: SessionData,
    data: CommunicationSettingsDTO,
  ): Promise<CommunicationSettingsDTO> {
    return postJson<CommunicationSettingsDTO, CommunicationSettingsDTO>(
      this.httpClient,
      ['api', 'communication'],
      data,
      sessionData,
    )
  }

  // User Bets

  getUserBets(
    sessionData: SessionData,
    languageId: number,
    startIndex: number = 0,
    pageSize: number = 10,
    betStates?: BetSlipState[],
    orderBy: string = 'id desc',
    includeDetails: boolean = false,
  ): Promise<PagedResult<Bet>> {
    const query = {} as any

    if (languageId) query.languageId = languageId
    if (betStates) query.betStates = betStates.join(',')
    if (orderBy) query.orderby = orderBy
    if (includeDetails != null) query.includeDetails = includeDetails

    return getJson<PagedResult<Bet>>(
      this.httpClient,
      ['api', 'account', 'userbets', startIndex, pageSize],
      sessionData,
      query,
    )
  }

  getUserBetDetails(sessionData: SessionData, betId: number, languageId: number): Promise<Bet> {
    const query = {
      languageId: languageId.toString(),
    }

    return getJson<Bet>(this.httpClient, ['api', 'account', 'userbets', betId], sessionData, query)
  }

  // User Bookings

  getUserBookings(
    sessionData: SessionData,
    accountId: number,
    startDate: string,
    endDate: string,
    startIndex: number = 0,
    pageSize: number = 20,
    languageId = 1,
    orderBy: string = 'id desc',
  ): Promise<PagedResult<UserAccountingListItem>> {
    const query = {
      startDate: startDate,
      endDate: endDate,
    } as any

    if (orderBy) query.orderby = orderBy

    return getJson<PagedResult<UserAccountingListItem>>(
      this.httpClient,
      ['api', 'account', 'booking', accountId, startIndex, pageSize, languageId],
      sessionData,
      query,
    )
  }

  // Bet Settings

  getNodeBetSettings(sessionData: SessionData): Promise<BranchBetSettings> {
    return getJson<BranchBetSettings>(this.httpClient, ['api', 'node', 'betsettings'], sessionData)
  }

  getUserBetSettings(sessionData: SessionData): Promise<UserDefinedBetSettings> {
    return getJson<UserDefinedBetSettings>(this.httpClient, ['api', 'settings', 'bet'], sessionData)
  }

  changeUserBetSettings(sessionData: SessionData, request: UserDefinedBetSettings): Promise<UserDefinedBetSettings> {
    return postJson<UserDefinedBetSettings, UserDefinedBetSettings>(
      this.httpClient,
      ['api', 'settings', 'bet'],
      request,
      sessionData,
    )
  }

  // Marketing Settings

  getUserMarketingSettings(sessionData: SessionData): Promise<UserDefinedMarketingSettings> {
    return getJson<UserDefinedMarketingSettings>(this.httpClient, ['api', 'settings', 'marketing'], sessionData)
  }

  changeUserMarketingSettings(
    sessionData: SessionData,
    request: UserDefinedMarketingSettings,
  ): Promise<UserDefinedMarketingSettings> {
    return postJson<UserDefinedMarketingSettings, UserDefinedMarketingSettings>(
      this.httpClient,
      ['api', 'settings', 'marketing'],
      request,
      sessionData,
    )
  }

  // Selflimits

  getUserSelfLimits(sessionData: SessionData): Promise<any> {
    return getJson<any>(this.httpClient, ['api', 'settings', 'selflimits'], sessionData)
  }

  changeUserSelfLimits(sessionData: SessionData, request: any): Promise<any> {
    return postJson<any, any>(this.httpClient, ['api', 'settings', 'selflimits'], request, sessionData)
  }

  // 2FA Settings

  getUser2FASettings(sessionData: SessionData): Promise<TwoFASettings> {
    return getJson<TwoFASettings>(this.httpClient, ['api', 'settings', '2fa'], sessionData)
  }

  changeUser2FASettings(sessionData: SessionData, request: TwoFASettings): Promise<TwoFASettings> {
    return postJson<TwoFASettings, TwoFASettings>(this.httpClient, ['api', 'settings', '2fa'], request, sessionData)
  }

  // Password

  changeUserPassword(sessionData: SessionData, request: ChangePasswordRequest): Promise<PasswordError> {
    return putJson<ChangePasswordRequest, PasswordError>(
      this.httpClient,
      ['api', 'auth', 'updatenewpassword'],
      request,
      sessionData,
    )
  }

  resetUserPassword(sessionData: SessionData, userId: number, request: any): Promise<ResetPasswordResult> {
    return putJson<any, ResetPasswordResult>(
      this.httpClient,
      ['api', 'auth', 'resetpassword', 'exato', 'facerecognition', userId],
      request,
      sessionData,
    )
  }

  completeResetUserPassword(sessionData: SessionData, token: string): Promise<UserLogonWithValidateTokenDTO> {
    return postJson<{ token: string }, UserLogonWithValidateTokenDTO>(
      this.httpClient,
      ['api', 'auth', 'resetpassword'],
      { token },
      sessionData,
    )
  }

  setNewUserPassword(sessionData: SessionData, token: string, newPassword: string): Promise<PasswordError> {
    return postJson<any, PasswordError>(
      this.httpClient,
      ['api', 'auth', 'setnewpassword'],
      { token, newPassword },
      sessionData,
    )
  }

  // Password Recovery

  getPasswordRecoveryQuestions(sessionData: SessionData, languageId: number): Promise<SecurityQuestion[]> {
    return getJson<SecurityQuestion[]>(
      this.httpClient,
      ['api', 'settings', 'securityQuestions', languageId],
      sessionData,
    )
  }

  changePasswordRecoveryQuestion(
    sessionData: SessionData,
    request: ChangeSecurityQuestionRequest,
    languageId: number,
  ): Promise<ResetPasswordStatusCode> {
    return postJson<ChangeSecurityQuestionRequest, ResetPasswordStatusCode>(
      this.httpClient,
      ['api', 'settings', 'securityQuestions', languageId],
      request,
      sessionData,
    )
  }

  // PIN

  changeUserPin(sessionData: SessionData, request: ChangePinRequest): Promise<boolean> {
    return putJson<ChangePinRequest, boolean>(this.httpClient, ['api', 'settings', 'pin'], request, sessionData)
  }

  // Bonus

  getUserBonuses(
    sessionData: SessionData,
    startIndex: number,
    pageSize: number,
    languageId: number,
    state?: GivenBonusState,
    orderBy: string = 'id desc',
  ): Promise<PagedResult<GivenBonusListItem>> {
    const query = {} as any

    if (orderBy) query.orderby = orderBy
    if (state != null) query.state = state

    return getJson<PagedResult<GivenBonusListItem>>(
      this.httpClient,
      ['api', 'bonus', 'paged', pageSize, startIndex, languageId],
      sessionData,
      query,
    )
  }

  getOpenUserBonusCount(sessionData: SessionData): Promise<number> {
    return getJson<number>(this.httpClient, ['api', 'bonus', 'opencount'], sessionData)
  }

  acceptBonus(sessionData: SessionData, bonusId: number): Promise<BookBonusResult> {
    return postJson<{ bonusId: number }, BookBonusResult>(
      this.httpClient,
      ['api', 'bonus', 'consume'],
      { bonusId },
      sessionData,
    )
  }

  rejectBonus(sessionData: SessionData, bonusId: number): Promise<BookBonusResult> {
    return postJson<{ bonusId: number }, BookBonusResult>(
      this.httpClient,
      ['api', 'bonus', 'reject'],
      { bonusId },
      sessionData,
    )
  }

  redeemVoucher(sessionData: SessionData, voucherCode: string): Promise<RedeemVoucherResult> {
    return postJson<{ voucherCode: string }, RedeemVoucherResult>(
      this.httpClient,
      ['api', 'bonus', 'redeemvoucher'],
      { voucherCode },
      sessionData,
    )
  }

  // Documents

  getUserDocuments(
    sessionData: SessionData,
    startIndex: number = 0,
    pageSize: number = 25,
    orderBy: string = 'id desc',
  ): Promise<PagedResult<UserDocument>> {
    const query = {} as any

    if (orderBy) query.orderby = orderBy

    return getJson<PagedResult<UserDocument>>(
      this.httpClient,
      ['api', 'documents', 'paged', pageSize, startIndex],
      sessionData,
      query,
    )
  }

  uploadUserDocument(sessionData: SessionData, type: DocumentTypes, data: FormData): Promise<UserDocument> {
    const query = {
      type: type as any,
    }

    return postFormDataJson(this.httpClient, ['api', 'documents', 'upload'], data, sessionData, query)
  }

  // Node common data

  getNodeLanguages(sessionData: SessionData): Promise<Language[]> {
    return getJson<Language[]>(this.httpClient, ['api', 'node', 'languages'], sessionData)
  }

  getNodeCountries(sessionData: SessionData): Promise<Country[]> {
    return getJson<Country[]>(this.httpClient, ['api', 'node', 'countries'], sessionData)
  }

  getNodeCurrencies(sessionData: SessionData): Promise<Currency[]> {
    return getJson<Currency[]>(this.httpClient, ['api', 'node', 'currencies'], sessionData)
  }

  // User avatar

  uploadUserAvatar(sessionData: SessionData, data: FormData) {
    return postFormDataJson(this.httpClient, ['api', 'account', 'avatar', 'upload'], data, sessionData)
  }

  deleteUserAvatar(sessionData: SessionData) {
    return deleteJson(this.httpClient, ['api', 'account', 'avatar', 'remove'], null, sessionData)
  }

  // Affiliate

  getAffiliateReport(
    sessionData: SessionData,
    startIndex: number,
    pageSize: number,
    startDate: string,
    endDate: string,
    orderBy?: string,
  ): Promise<AffiliateDTO> {
    const query = {
      startDate,
      endDate,
    } as any

    if (orderBy) query.orderBy = orderBy

    return getJson<AffiliateDTO>(
      this.httpClient,
      ['api', 'affiliate', 'report', startIndex, pageSize],
      sessionData,
      query,
    )
  }

  // Display Format

  getDisplayFormats(sessionData: SessionData): Promise<UserDisplayFormatPreference> {
    return getJson<UserDisplayFormatPreference>(this.httpClient, ['api', 'settings', 'displayformat'], sessionData)
  }

  changeDisplayFormats(
    sessionData: SessionData,
    request: UserDisplayFormatPreference,
  ): Promise<UserDisplayFormatPreference> {
    return postJson<UserDisplayFormatPreference, UserDisplayFormatPreference>(
      this.httpClient,
      ['api', 'settings', 'displayformat'],
      request,
      sessionData,
    )
  }

  // Reality Check

  getRealityCheckLimits(
    sessionData: SessionData,
    startDate: string,
    endDate: string,
    includeStakesOfOpenBets: boolean,
  ): Promise<Rating[]> {
    const query = {
      from: startDate,
      to: endDate,
      includeStakesOfOpenBets,
    } as any

    return getJson<Rating[]>(this.httpClient, ['api', 'account', 'reality', 'check'], sessionData, query)
  }

  // Exato Face Recognition

  getExatoFaceRecognitionEnabledState(
    sessionData: SessionData,
    triggerReason: FaceRecognitionTriggerReason = FaceRecognitionTriggerReason.UpdatePersonalInformation,
  ): Promise<boolean> {
    const query = {
      triggerReason,
    } as any
    return getJson<boolean>(
      this.httpClient,
      ['api', 'auth', 'exato', 'facerecognition', 'isenabled'],
      sessionData,
      query,
    )
  }

  getExatoFaceRecognitionUrl(sessionData: SessionData): Promise<ExatoUrlResult> {
    return getJson<ExatoUrlResult>(this.httpClient, ['api', 'auth', 'exato', 'facerecognition'], sessionData)
  }

  // TnC & Privacy

  checkTermsAndConditionsAcceptance(sessionData: SessionData): Promise<TermsAndConditionsCheckResultDTO> {
    return getJson<TermsAndConditionsCheckResultDTO>(
      this.httpClient,
      ['api', 'auth', 'termsandconditions', 'check'],
      sessionData,
    )
  }

  acceptTermsAndConditions(
    sessionData: SessionData,
    request: UserTermsAndConditionsRequest,
  ): Promise<UserTermsAndConditions> {
    return postJson<UserTermsAndConditionsRequest, UserTermsAndConditions>(
      this.httpClient,
      ['api', 'account', 'termsandconditions'],
      request,
      sessionData,
    )
  }

  checkPrivacyPolicyAcceptance(sessionData: SessionData): Promise<PrivacyPolicyCheckResultDTO> {
    return getJson<PrivacyPolicyCheckResultDTO>(this.httpClient, ['api', 'auth', 'privacypolicy', 'check'], sessionData)
  }

  acceptPrivacyPolicy(sessionData: SessionData, request: UserPrivacyPolicyRequest): Promise<UserPrivacyPolicy> {
    return postJson<UserPrivacyPolicyRequest, UserPrivacyPolicy>(
      this.httpClient,
      ['api', 'account', 'privacypolicy'],
      request,
      sessionData,
    )
  }
  geoComplyLincese(sessionData: SessionData, request: GeoComplyLicenseRequest): Promise<GeoComplyLicense> {
    return postJson<GeoComplyLicenseRequest, GeoComplyLicense>(
      this.httpClient,
      ['api', 'auth', 'geocomply', 'license'],
      request,
      sessionData,
    )
  }

  setGeoComplyGeoLocation(sessionData: SessionData, request: GeoComplyGeolocationRequest): Promise<boolean> {
    return postJson<GeoComplyGeolocationRequest, boolean>(
      this.httpClient,
      ['api', 'auth', 'geocomply', 'geolocation'],
      request,
      sessionData,
    )
  }
}
