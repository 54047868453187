import dynamic from 'next/dynamic'

const CardsListWidget = dynamic(() => import('./../components/widgets/CardsListWidget'))
const CasinoGamesWidget = dynamic(() => import('./../components/widgets/CasinoGamesWidget'))
const FavoritesWidget = dynamic(() => import('./../components/widgets/FavoritesWidget'))
const HotBetWidget = dynamic(() => import('./../components/widgets/HotBetWidget'))
const HTMLWidget = dynamic(() => import('./../components/widgets/HTMLWidget'))
const LastMinuteWidget = dynamic(() => import('./../components/widgets/LastMinuteWidget'))
const LiveGamesWidget = dynamic(() => import('./../components/widgets/LiveGamesWidget'))
const NextLeagueEventWidget = dynamic(() => import('./../components/widgets/NextLeagueEventWidget'))
const OpenBetsWidget = dynamic(() => import('./../components/widgets/OpenBetsWidget'))
const OpenBonusWidget = dynamic(() => import('./../components/widgets/OpenBonusWidget'))
const RankingWidget = dynamic(() => import('./../components/widgets/RankingWidget'))
const SliderWidget = dynamic(() => import('./../components/widgets/SliderWidget'))
const TopGamesWidget = dynamic(() => import('./../components/widgets/TopGamesWidget'))

export enum ExtendedLinkType {
  Link = 'href',
  Game = 'game',
  CasinoGame = 'casino-game',
  League = 'league',
  CasinoCategory = 'casino-category',
}

export enum NextWoqWidget {
  CardsListWidget = '78f0fc6a-45c6-4da6-9e85-24baf847ec6d',
  CasinoGamesWidget = '0bd9092d-f9e6-4808-8221-6d267f61b5b3',
  FavoritesWidget = 'eaa54697-c3e9-40ce-9ec0-d87f7eb2b0e7',
  HotBetWidget = 'cd0c60fb-050f-4a66-b95e-82be06b6ef67',
  HTMLWidget = '6C059D3C-D5E6-4D62-B2A5-22DD6864A64B',
  NextLeagueEventsWidget = '2448ba82-51e7-4b36-983e-01e564c06af2',
  LastMinuteWidget = 'e69f255b-af82-41a9-8a5d-621bc2d23d48',
  LiveGamesWidget = '4087820f-518e-426e-9673-9921a770019f',
  OpenBetsWidget = 'ca7454b8-1c63-45cd-8547-f797927833fc',
  OpenBonusWidget = 'f5eb832a-1036-4f1e-aeb8-38442dce5637',
  RankingWidget = '77b894a5-ffb9-4b1e-a6b5-cfb3fe2f1aeb',
  SliderWidget = 'e3b2f7eb-be38-41ce-9490-77d1b7379fe6',
  TopGamesWidget = 'a447e41e-8e09-4e2b-90a0-e8b645179234',
}

export const getWidgetByUniqueIdentifier = (uniqueIdentifier: string) => {
  switch (uniqueIdentifier) {
    case NextWoqWidget.FavoritesWidget:
      return FavoritesWidget
    case NextWoqWidget.NextLeagueEventsWidget:
      return NextLeagueEventWidget
    case NextWoqWidget.OpenBetsWidget:
      return OpenBetsWidget
    case NextWoqWidget.TopGamesWidget:
      return TopGamesWidget
    case NextWoqWidget.CardsListWidget:
      return CardsListWidget
    case NextWoqWidget.SliderWidget:
      return SliderWidget
    case NextWoqWidget.CasinoGamesWidget:
      return CasinoGamesWidget
    case NextWoqWidget.HotBetWidget:
      return HotBetWidget
    case NextWoqWidget.LastMinuteWidget:
      return LastMinuteWidget
    case NextWoqWidget.HTMLWidget:
      return HTMLWidget
    case NextWoqWidget.RankingWidget:
      return RankingWidget
    case NextWoqWidget.OpenBonusWidget:
      return OpenBonusWidget
    case NextWoqWidget.LiveGamesWidget:
      return LiveGamesWidget
  }
}

export const getCmsContentDataField = (cmsContent: any, fieldIdentifier: string): any => {
  return cmsContent?.fields?.find((field) => field['identifier'] === fieldIdentifier)
}

export const getCmsContentDataFieldValue = (cmsContent: any, fieldIdentifier: string): any => {
  const dataField = getCmsContentDataField(cmsContent, fieldIdentifier)
  if (!dataField) return null

  let value = null
  switch (dataField.fieldType) {
    case 'string':
    case 'html':
      value = dataField.value?.str
      break
    case 'boolean':
      value = dataField.value?.bool
      break
    case 'link':
      value = dataField.value?.href
      break
    case 'extended-link':
      value = getExtendedLinkValue(dataField)
      break
    case 'gradient':
      value = dataField.value
      break
    case 'color':
      value = dataField.value?.hexCode
      break
    case 'league':
    case 'game':
      value = dataField.value?.id
      break
    case 'sport-ids':
      value = dataField.value?.ids
      break
    case 'image':
      value = dataField.value?.fileUrl
      break
    case 'number':
      value = dataField.value?.num
      break
    case 'datetime':
      value = dataField.value?.dt
      break
    case 'collection':
      value = []
      dataField.value?.content?.forEach((c) => {
        const content = getPropsFromContentFields(c)
        value.push(content)
      })
      break
  }

  return value
}

export const getPropsFromContentFields = (cmsContent: any) => {
  const props = {}
  cmsContent?.fields?.forEach((field) => {
    props[field.identifier] = getCmsContentDataFieldValue(cmsContent, field.identifier)
  })
  return props
}

export const getExtendedLinkValue = (dataField: any) => {
  const type = dataField.value?.type
  let value = null

  switch (dataField.value?.type) {
    case ExtendedLinkType.Link:
      value = dataField.value.linkHref
      break
    case ExtendedLinkType.Game:
      value = dataField.value.gameId
      break
    case ExtendedLinkType.League:
      value = dataField.value.leagueId
      break
    case ExtendedLinkType.CasinoGame:
      value = dataField.value.casinoGameId
      break
    case ExtendedLinkType.CasinoCategory:
      value = {
        casinoCategoryId: dataField.value.casinoCategoryId,
        casinoCategoryName: dataField.value.casinoCategoryName,
      }
      break
  }

  return { type, value }
}

export const getWidgetFromCmsPage = (
  cmsPage: any,
  widgetIdentifier: NextWoqWidget,
): { widget: any; widgetProps: any } => {
  const widgets = cmsPage?.referencedWidgets
  const widget = widgets?.find((w) => w.referencedContent?.type?.uniqueId === widgetIdentifier)
  const widgetProps = getPropsFromContentFields(widget?.referencedContent)
  return { widget, widgetProps }
}

export const isCmsPageEnabled = (cmsPage: any): boolean => {
  return cmsPage?.state === 'published'
}
