import { Constants } from '../../util/constants'
import { isEmpty } from '../../util/object'
import { getRelativeUrl } from '../../util/url'
import { AccountClient } from './account'
import { BanksClient } from './banks'
import { BetsClient } from './bets'
import { CommonClient } from './common'
import { ContentClient } from './content'
import { SessionClient, AxiosHttpClient, HttpClient } from './core'
import { GamingClient } from './gaming'
import { OddsClient } from './odds'
import { PaymentClient } from './payment'

type Clients = {
  httpClient: HttpClient
  common?: CommonClient
  session: SessionClient
  account?: AccountClient
  banks?: BanksClient
  odds?: OddsClient
  bets?: BetsClient
  payment?: PaymentClient
  content?: ContentClient
  gaming?: GamingClient
}

let clients: Clients = {} as any

export const useSessionClient = (): SessionClient => {
  const client = getOrCreateClients()
  return client.session
}

export const useCommonClient = (): CommonClient => {
  const client = getOrCreateClients()
  if (!client.common) client.common = new CommonClient(client.httpClient)
  return client.common
}

export const useOddsClient = (): OddsClient => {
  const client = getOrCreateClients()
  if (!client.odds) client.odds = new OddsClient(client.httpClient)
  return client.odds
}

export const useBetsClient = (): BetsClient => {
  const client = getOrCreateClients()
  if (!client.bets) client.bets = new BetsClient(client.httpClient)
  return client.bets
}

export const useAccountClient = (): AccountClient => {
  const client = getOrCreateClients()
  if (!client.account) client.account = new AccountClient(client.httpClient)
  return client.account
}

export const useBanksClient = (): BanksClient => {
  const client = getOrCreateClients()
  if(!client.banks) client.banks = new BanksClient(client.httpClient);
  return client.banks
}

export const usePaymentClient = (): PaymentClient => {
  const client = getOrCreateClients()
  if (!client.payment) client.payment = new PaymentClient(client.httpClient)
  return client.payment
}

export const useContentClient = (): ContentClient => {
  const client = getOrCreateClients()
  if (!client.content) client.content = new ContentClient(client.httpClient)
  return client.content
}

export const useGamingClient = (): GamingClient => {
  const client = getOrCreateClients()
  if (!client.gaming) client.gaming = new GamingClient(client.httpClient)
  return client.gaming
}

const getOrCreateClients = (): Clients => {
  if (isEmpty(clients)) {
    const httpClient = new AxiosHttpClient({
      baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
      externalBaseUrl: process.env.NEXT_PUBLIC_EXTERNAL_API_BASE_URL,
      application: {
        name: 'NEXT WoQ',
        guid: Constants.AppId,
      },
    })
    clients = {
      httpClient,
      session: new SessionClient(httpClient),
    }
  }
  return clients
}

export const handleHttpErrorStatus = (status?: number) => {
  if (status == null || document == null) return

  const referrer = getRelativeUrl(document.location.href)

  switch (status) {
    case 401:
    case 600:
      document.location.href = '/account/login?redirectTo=' + referrer
  }
}
