import { FrontEndSettingsDTO } from '@arland-bmnext/webapps-api-data'

export type PageFilter = (
  req,
  cmsPage: any,
  frontendSettings: FrontEndSettingsDTO,
) => PageFilterResult | Promise<PageFilterResult>

export type PageFilterResult = {
  authenticated: boolean
  notFound?: boolean
  redirect?: {
    destination?: string
    permanent?: boolean
  }
}

export enum PageFilterType {
  WebsiteRequiresAuthentication,
  PageRequiresAuthentication,
  PageIsInactive,
  MustChangePassword,
  MustResetPassword,
  MustCompleteMissingFields,
  WebsiteIsDisabled,
  MustAcceptTermsAndConditions,
  MustAcceptPrivacyPolicy,
}
