export const truncateBefore = (value: string, pattern: string) => {
  return value.slice(value.indexOf(pattern) + pattern.length)
}

export const truncateAfter = (value: string, pattern: string) => {
  const patternIndex = value.indexOf(pattern)
  if (patternIndex < 0) return value
  return value.slice(0, patternIndex)
}

export const isNullOrEmpty = (value: any): boolean => {
  return (
    value == null ||
    value === '' ||
    (value.constructor === Array && value?.length === 0) ||
    (value.constructor === Object && Object.keys(value as any)?.length == 0)
  )
}

export const fromBase64String = (str: string) => {
  const buffer = Buffer.from(str, 'base64')
  return buffer.toString()
}

export const isValidEmail = (value: string) => {
  return value.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
}

export const toNormalForm = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const maskString = (str: string) => {
  return str
    ?.split(' ')
    ?.map((word) => {
      if (word.length === 2) {
        // Mask the second letter if the word has exactly 2 characters
        return word[0] + '*'
      } else if (word.length > 2) {
        // Mask the middle characters for words longer than 2 characters
        return word[0] + '*'.repeat(word.length - 2) + word[word.length - 1]
      } else {
        // For words with 1 character, return it as is
        return word
      }
    })
    ?.join(' ')
}
